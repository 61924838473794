

<script>
import axios from "axios";
import Config from "@/config";
export default {
    name:'Track',
    mounted(){
        
        let $this =this;
        let custId =this.$route.query.cust_id;
        let bid =this.$route.query.bid;
        localStorage.setItem('ref_banner_id',bid);
        localStorage.setItem('ref_cust_id',custId);
        axios.get(`${Config.BASE_URL}getUrlKeyByBannerId?bannerId=${bid}`).then(function(response){
            let urlKey='';
            let redirectUrl='';
           
            if(response.data.Data.prodUrlKey!=''){
                urlKey =response.data.Data.prodUrlKey;               
                redirectUrl="/Product-detail/"+urlKey;
            }else if(response.data.Data.catUrlKey!=''){
                urlKey =response.data.Data.catUrlKey;
                redirectUrl="/products/"+urlKey;
            }
        //   console.log(response.data.Data);
             
            $this.$router.push({
                path: `${redirectUrl}`
            
            });
        });
    }
}
</script>

<style>

</style>